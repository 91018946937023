import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";
import { gaTag } from '../../monitoring-tags';
import { useAppStore } from '@/stores';

const Analytics = () => {
  if (gaTag) {
    ReactGA.initialize(gaTag, {
      gtagOptions: {
        debug_mode: true, // Enables debug mode for gtag.js
      },
    });
  }
  const location = useLocation();
  const { currentUser } = useAppStore();

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname });
    if (currentUser?.user?.id) {
      ReactGA.set({ userId: currentUser.user.id });
    }
  }, [location, currentUser]);

  return null;
};

export default Analytics;
