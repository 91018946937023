import { lazy, Suspense, useEffect } from 'react';
import { ThemeProvider } from '@mui/material';
import { Route, Routes, useLocation } from 'react-router-dom';
import theme from './theme';
import { ToastContainer } from 'react-toastify';
import { Loader, PrivateRoute, SuspenseLoader } from '@/components';
import routes from './router';
import { ROUTES } from './constants';
import  BackgroundTask  from './components/BackgroundTask';
import './app.css';
import ProjectStatus from './pages/project-status';
import EditHub from './pages/edit-hub';
import Analytics from './components/analytics';
import * as Sentry from '@sentry/react'; // Import Sentry
import Cookies from 'js-cookie';
import ReactGA from "react-ga4";
const Login = Loader(lazy(() => import('./pages/login')));

const App = () => {
  const location = useLocation();

  useEffect(() => {
      const user = JSON.parse(Cookies.get('user') || '{}');
      if (user) {
        // Assigning the customer id to the user id of Sentry
        Sentry.setUser({
            id: user.id,
            email: user.email,
            username: user.username,
        });
        ReactGA.set({ userId: user.id }); //assigning Google Analytics user id
      } else {
        Sentry.setUser(null); // Clears the user context when logging out
      }
      Sentry.captureMessage(`${user.email} Navigated to ${location.pathname}`, "info");
    }, [location]);
  // useEffect(() => {
  //   const user = JSON.parse(Cookies.get('user') || '{}');
  //   // Capture a breadcrumb on route change
  //   Sentry.captureMessage(`${user.email} Navigated to ${location.pathname}`, "info");

  // }, [location]);

  return (
    <ThemeProvider theme={theme}>
      <Suspense fallback={<SuspenseLoader />}>
      <BackgroundTask></BackgroundTask>
        <Analytics />
        <Routes>

          <Route element={<PrivateRoute />}>
            {routes.map(route => (
              <Route
                key={route.path}
                path={route.path}
                element={route.element}
              />
            ))}
          </Route>

          <Route path={ROUTES.LOGIN} element={<Login />} />
          <Route path={ROUTES.PROJECT_STATUS} element={<ProjectStatus />} />
          <Route path={ROUTES.EDIT_HUB} element={<EditHub />} />
        </Routes>
      </Suspense>

      <ToastContainer
        closeOnClick
        autoClose={2000}
        position='top-center'
        theme='colored'
        pauseOnHover
      />
    </ThemeProvider>
  );
};

export default App;
